import { createAction, props } from '@ngrx/store';

export const createPasswordResetRequest = createAction(
  '[Password Reset] Create Password Reset Request',
  props<{ email: string }>(),
);

export const createPasswordResetSuccess = createAction(
  '[Password Reset] Create Password Reset Success',
);

export const createPasswordResetFailure = createAction(
  '[Password Reset] Create Password Reset Failure',
  props<{ error: any }>(),
);

export const checkTokenRequest = createAction(
  '[Password Reset] Check Token Request',
  props<{ token: string; email: string }>(),
);

export const checkTokenSuccess = createAction(
  '[Password Reset] Check Token Success',
);

export const checkTokenFailure = createAction(
  '[Password Reset] Check Token Failure',
  props<{ error: any }>(),
);

export const confirmPasswordResetRequest = createAction(
  '[Password Reset] Confirm Password Reset Request',
  props<{ password: string; token: string; email: string }>(),
);

export const confirmPasswordResetSuccess = createAction(
  '[Password Reset] Confirm Password Reset Success',
);

export const confirmPasswordResetFailure = createAction(
  '[Password Reset] Confirm Password Reset Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Password Reset] Reset State');
