import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PasswordResetService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PasswordResetStoreEffects {
  constructor(
    private dataService: PasswordResetService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private router: Router,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  createPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createPasswordResetRequest),
      switchMap(({ email }) =>
        this.dataService.createPasswordReset(email).pipe(
          map(() => {
            this.modalService.success({
              nzTitle: this.translate.instant('done'),
              nzContent: this.translate.instant(
                'messagere_reset_password_email',
              ),
            });
            this.router.navigate(['/login']);
            return fromActions.createPasswordResetSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createPasswordResetFailure(error));
          }),
        ),
      ),
    ),
  );

  checkToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkTokenRequest),
      switchMap(({ token, email }) =>
        this.dataService.checkToken(token, email).pipe(
          map(() => {
            return fromActions.checkTokenSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            this.router.navigate(['/login']);
            return of(fromActions.checkTokenFailure(error));
          }),
        ),
      ),
    ),
  );

  confirmPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.confirmPasswordResetRequest),
      switchMap(({ password, token, email }) =>
        this.dataService.confirmPasswordReset(token, password, email).pipe(
          map(() => {
            this.modalService.success({
              nzTitle: this.translate.instant('done'),
              nzContent: this.translate.instant(
                'messagere_reset_password_complete_email',
              ),
            });
            this.router.navigate(['/login']);
            return fromActions.confirmPasswordResetSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            this.router.navigate(['/login']);
            return of(fromActions.confirmPasswordResetFailure(error));
          }),
        ),
      ),
    ),
  );
}
