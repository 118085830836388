import { PasswordResetStep } from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  step: PasswordResetStep;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  step: 'email_step' as PasswordResetStep,
};
