import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';
import { PasswordResetStep } from '../../models';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.createPasswordResetRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.createPasswordResetSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.createPasswordResetFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.checkTokenRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.checkTokenSuccess, (state) => ({
    ...state,
    isLoading: false,
    step: 'password_step' as PasswordResetStep,
  })),
  on(fromActions.checkTokenFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.confirmPasswordResetRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.confirmPasswordResetSuccess, () => fromState.initialState),
  on(fromActions.confirmPasswordResetFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function passwordResetReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
